import React from "react";
/* eslint-disable max-len */

import { useStaticQuery, graphql, Link } from "gatsby";

import SEO from "../../components/Seo";
import BlogPage from "../../components/template-parts/BlogPage";
import { ImgContent } from "../../components/template-parts/BlogPage/styles";

function Dominio() {
	const { banner, livro } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "curso/banner10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      livro: file(relativePath: { eq: "books/dominio-do-yin-da-fertilidade-maternidade.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

	const bannerPage = banner.childImageSharp.fluid;
	const content = {
		title: `Domínio do Yin`,
		featuredImage: bannerPage,
	};
	return (
		<>
			<SEO
				title="Domínio do Yin - Da Fertilidade à Maternidade - A Mulher e suas Fases na Medicina Tradicional Chinesa"
				keywords="Domínio do Yin, helena campiglia, helenacampiglia, medica clinica geral, medicaclinicageral, acupuntura, infertilidade, mente e corpo, meditação, fmusp"
				description="Os temas são discutidos a cada capítulo visando tanto a leitura de pacientes e leigos, quanto a de acupunturistas especializados na área, começando com os conceitos mais abrangentes e acessíveis a todos e aprofundando-se, quando necessário, nos detalhes do tratamento de cada uma dessas fases da vida da mulher."
			/>
			<BlogPage content={content}>
				<p>
					Fertilidade, maternidade, gestação, parto, amamentação e menopausa: este livro aborda
					algumas das mais importantes questões do feminino sob a ótica da Medicina Tradicional
					Chinesa{" "}
				</p>
				<div>
					<ImgContent fluid={livro.childImageSharp.fluid} />
				</div>
				<p>
					Os temas são discutidos a cada capítulo visando tanto a leitura de pacientes e leigos,
					quanto a de acupunturistas especializados na área, começando com os conceitos mais
					abrangentes e acessíveis a todos e aprofundando-se, quando necessário, nos detalhes do
					tratamento de cada uma dessas fases da vida da mulher.
				</p>
				<p>
					Devido à larga experiência da autora no tratamento de pacientes inférteis, o livro
					discorre mais longamente a respeito da fertilidade e dos benefícios da acupuntura no
					tratamento desta. Questões relacionadas ao estilo de vida, à alimentação e até mesmo à
					vida moderna são aqui propostas para bem se compreender a fertilidade natural, sempre
					levando em conta as referências do pensamento chinês que fundamentam a boa saúde.
				</p>
				<p>
					<span>Veja alguns trechos do Livro:</span>
				</p>
				<p>
					Nos tempos atuais, a mulher moderna está exposta a novas exigências culturais, familiares
					e de trabalho que afetam não só seu estilo de vida e escolhas pessoais como também o
					próprio funcionamento de seu corpo, refletindo-se nas diversas fases da expressão do
					feminino: os ciclos menstruais, a fertilidade, a maternidade e a menopausa.
				</p>
				<p>
					{" "}
					A <Link to="/medicina-tradicional-chinesa">Medicina Chinesa</Link> apresenta uma visão
					bastante profunda do sutil equilíbrio entre o <span>Yin</span> e o <span>Yang</span> e
					suas implicações na saúde e na doença:
				</p>
				<blockquote>
					<p>
						“O Yin é o símbolo da suavidade, da devoção, da concentração, do recolhimento, da
						profundidade e da adaptabilidade, entretanto, mais e mais mulheres encontram-se, nos
						dias atuais, com uma vida extremamente atribulada, ativa e competitiva, característica
						do Yang…Na cultura chinesa antiga, a passividade era um atributo que indicava
						maleabilidade e a sabedoria de aceitar o seu destino. Ao buscar integrar-se aos valores
						Yang, a mulher não mudou apenas seu comportamento, mas alterou e altera sua forma, seu
						corpo e sua fisiologia.
					</p>
					<p>
						O Yang tornou-se mais presente: atividade incessante, produção de bens de consumo,
						comunicação acelerada, supressão ou alteração dos ciclos naturais (ciclo menstrual,
						ciclo do sono, ciclos alimentares e até mesmo ciclos das estações do ano), diminuição
						dos momentos de recolhimento e reflexão, desvalorização da espera e da passividade.
					</p>
					<p>
						Segundo a Medicina Tradicional Chinesa, quando o Yang se torna excessivo acaba por
						consumir o Yin. Isso significa que, aos poucos, os atributos Yin do corpo e da psique da
						mulher estão se desgastando. Toda essa nova atividade e produção afetam o Yin,
						diminuindo-o. Do ponto de vista físico, o aparelho reprodutivo – útero, ovários, óvulos
						– é considerado Yin, não sendo, assim, difícil imaginar as conseqüências de uma vida que
						exclui ou minimiza o repouso, o acolhimento, a introspecção e a passividade. Ao longo
						deste livro, veremos problemas relacionados à fertilidade, à amamentação, ao parto, à
						menopausa, muitos deles ligados ao atual modo de vida das mulheres e, portanto à queda
						do Yin.{" "}
					</p>
					<p>
						Não é apenas a mulher quem sofre com essas mudanças de estilo de vida. Em uma escala
						ainda maior, as mudanças sociais, comportamentais e de hábitos têm afetado o planeta,
						com consequências ecológicas seriíssimas como se está vendo cada dia mais. O
						desequilíbrio do Yin e do Yang reflete-se na exploração dos recursos naturais da terra
						(Yin) de modo incessante, transformando tais recursos (petróleo, cana de açúcar, milho)
						em energia pura (Yang) e esgotando as fontes originais. O que fazemos com a natureza é
						equivalente à nossa atitude em relação ao corpo: agimos como se não houvesse limites a
						serem respeitados, como se fôssemos imortais…”
					</p>
				</blockquote>
				<p>
					Este livro expõe, dentro da ótica da Medicina Tradicional Chinesa, a compreensão, o
					diagnóstico e o tratamento das alterações do Yin ligadas à função reprodutiva e suas
					implicações físicas e psicológicas.
				</p>
				<div className="button">
					<Link
						to="https://amzn.to/4eIQriA"
						target="_blank">
						<span>Compre Agora!</span>
					</Link>
				</div>
			</BlogPage>
		</>
	);
}

export default Dominio;
